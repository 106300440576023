import React from "react";
import { useTheme, useToast } from "@chakra-ui/react";
import { get } from "lodash";
import { navigate } from "gatsby";
import { Field, Form as FinalForm } from "react-final-form";
import toastMessage from "../../../utilities/toastMessage";
import Box from "../../base/Box";
import Input from "../../base/Input";
import { required } from "../../../validations";
import FormError from "../../base/FormError";
import Button from "../../base/Button";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Heading from "../../base/Heading";
import { post } from "../../../utilities/http";
import CustomCheck from "../../base/CustomCheck";
import TextArea from "../../base/TextArea";
import Icon from "../../Icon";
import { STATUS } from "../../../utilities/constants";

const AddNodeForm = () => {
  const theme = useTheme();
  const toast = useToast();
  const gap = 7;
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: "60%",
    mb: gap,
    mx: 4,
    width: "60%",
  };

  const colFull = {
    minWidth: "100%",
  };

  const modelOptions = [
    { label: "AP42", value: "ap42" },
    { label: "AP62", value: "ap62" },
    { label: "M4", value: "m4" },
  ];

  return (
    <Route layout="admin">
      <Section>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: ["90%", null, null, "70%"],
            mb: 4,
            mx: "auto",
          }}
        >
          <Heading {...theme.variant.heading}>Añadir nuevo nodo</Heading>
        </Box>
        <Box
          sx={{
            bg: "white",
            borderRadius: 4,
            maxWidth: "90%",
            mx: "auto",
            px: 5,
            py: 10,
          }}
        >
          <FinalForm
            initialValues={{
              model: get(modelOptions, "[0].value"),
            }}
            onSubmit={async (values) => {
              try {
                const payload = {
                  comments: get(values, "comments", ""),
                  imei: values.imei,
                  mac_eth: values.macEthernet,
                  mac_w1: values.macW1,
                  mac_w2: values.macW2,
                  model: values.model,
                  name: values.name,
                  purchased: values.purchased,
                  serial: values.serial,
                };

                const data = await post("/add-node", payload);
                if (data) {
                  toastMessage({
                    message:
                      get(data, "success") === STATUS.INACTIVE
                        ? get(data, "msm", "Internal server Error")
                        : "Node Added!",
                    toast,
                    type:
                      get(data, "success") === STATUS.INACTIVE
                        ? "error"
                        : "success",
                  });
                }
                navigate("/nodes");
              } catch (e) {
                toastMessage({
                  message: e.message,
                  toast,
                  type: "error",
                });
              }
            }}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    as={Field}
                    component={Input}
                    name="name"
                    placeholder="Nombre del nodo"
                    validate={required}
                    width="100%"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as={Field}
                    component={Input}
                    name="macEthernet"
                    placeholder="MAC ethernet"
                    validate={required}
                    width="100%"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as={Field}
                    component={Input}
                    name="macW1"
                    placeholder="MAC W1"
                    validate={required}
                    width="100%"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as={Field}
                    component={Input}
                    name="macW2"
                    placeholder="MAC W2"
                    validate={required}
                    width="100%"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as={Field}
                    component={Input}
                    name="imei"
                    placeholder="IMEI"
                    validate={required}
                    width="100%"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as={Field}
                    component={Input}
                    name="serial"
                    placeholder="Serial"
                    validate={required}
                    width="100%"
                    wrapperSx={colStyled}
                  />
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: ["column", null, "row"],
                      justifyContent: "center",
                      mb: gap,
                      mx: 4,
                      width: "60%",
                    }}
                  >
                    <Box
                      as="label"
                      sx={{
                        alignSelf: "center",
                        color: "text.primary",
                        display: "block",
                        fontSize: 1,
                        fontWeight: "regular",
                        letterSpacing: 0,
                        mr: 3,
                        my: 2,
                        width: ["50%", null, "25%"],
                      }}
                    >
                      *Modelo:
                    </Box>
                    {modelOptions.length > 0 &&
                      modelOptions.map((option) => (
                        <Box
                          sx={{
                            alignItems: "flex-start",
                            display: "flex",
                            flexDirection: "column",
                            m: [3, null, 0],
                            width: ["50%", null, "25%"],
                          }}
                        >
                          <Box
                            key={option.value}
                            as={Field}
                            component={CustomCheck}
                            customActiveContent={
                              <Icon svg="radioActive" sx={{ width: "24px" }} />
                            }
                            customContent={
                              <Icon
                                svg="radioInactive"
                                sx={{ width: "24px" }}
                              />
                            }
                            htmlFor={option.value}
                            label={option.label}
                            labelSx={{
                              display: "flex",
                              flexWrap: "wrap",
                              fontSize: 1,
                              fontWeight: "regular",
                            }}
                            name="model"
                            onClick={() => {}}
                            type="radio"
                            validate={required}
                            value={option.value}
                          />
                        </Box>
                      ))}
                  </Box>
                  <Box
                    as={Field}
                    component={Input}
                    name="purchased"
                    type="datetime-local"
                    validate={required}
                    width="100%"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as={Field}
                    component={TextArea}
                    name="comments"
                    placeholder="Comments"
                    rows={1}
                    value=""
                    wrapperSx={colStyled}
                  />
                  <FormError>{formContext.submitError}</FormError>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      my: 5,
                      ...colFull,
                    }}
                  >
                    <Button
                      disabled={formContext.pristine || formContext.submitting}
                      submitting={formContext.submitting}
                      sx={{ mr: 3, width: "130px" }}
                      type="submit"
                      variant="primary"
                    >
                      Guardar
                    </Button>
                    <Button
                      onClick={() => {
                        navigate("/nodes");
                      }}
                      sx={{ ml: 3, width: "130px" }}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          />
        </Box>
      </Section>
    </Route>
  );
};

export default AddNodeForm;
