import PropTypes from "prop-types";
import React from "react";
import TextareaAutosize from "react-autosize-textarea";
import { useTheme } from "@chakra-ui/react";
import Box from "../Box";
import InputWrapper from "../InputWrapper";

const TextArea = ({ input, ...rest }) => {
  const theme = useTheme();
  return (
    <InputWrapper input={input} {...rest}>
      {({ error, ...inputRest }) => (
        <Box
          as={TextareaAutosize}
          async
          sx={{
            ...theme.variant.input.primary,
            ":focus-visible": {
              borderColor: "onlineGreen",
              boxShadow: 2,
              outline: "none",
            },
            borderColor: error ? "error" : null,
          }}
          width="100%"
          {...inputRest}
          {...input}
        />
      )}
    </InputWrapper>
  );
};

TextArea.propTypes = {
  input: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
};

export default TextArea;
