import React, { useEffect, useState } from "react";
import { useToast, useTheme } from "@chakra-ui/react";
import { get } from "lodash";
import { navigate } from "gatsby";
import moment from "moment";
import { Form as FinalForm } from "react-final-form";
import { useLocation } from "@reach/router";

import { get as GET, post } from "../../../utilities/http";
import toastMessage from "../../../utilities/toastMessage";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Box from "../../base/Box";
import Heading from "../../base/Heading";
import Button from "../../base/Button";
import Spinner from "../../base/Spinner";
import Table from "../../Table";
import Icon from "../../Icon";
import { STATUS, TOTAL_JUMP_PAGES } from "../../../utilities/constants";
import Modal from "../../base/Modal";
import dashboardConstants from "../../../utilities/dashboard-constants";
import generateSearchString from "../../../utilities/generate-search-string";
import responsiveCss from "../../../utilities/responsiveCss";
import DashboardPaginator from "../../CommonComponents/DashboardPaginator";
import searchMultiple from "../../../utilities/searchMultiple";

const NodeListingPage = () => {
  const theme = useTheme();
  const toast = useToast();
  const location = useLocation();
  const [node, setNode] = useState(null);
  const [nodeData, setNodeData] = useState({});
  const [sample, setSample] = useState(true);
  const [searchValue, setSearchValue] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteToggle = () => {
    setDeleteModal(!deleteModal);
  };
  const sampleToggle = () => {
    setSample(!sample);
  };
  const { filters, pageCriteria } = dashboardConstants.getCriteria({
    search: location.search,
    type: "nodes",
  });
  const totalJumpToPages = responsiveCss(
    TOTAL_JUMP_PAGES.MOBILE,
    TOTAL_JUMP_PAGES.DESKTOP
  );
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
        page: pageCriteria,
      });
      const data = await GET(`/listing-nodes?${generateSearchString(query)}`);
      if (data) {
        const filteredNodeData = searchValue
          ? searchMultiple(get(data, "data", []), searchValue)
          : get(data, "data", []);
        setNodeData({ ...data, data: filteredNodeData });
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [sample, location]);
  const tableHeader = [
    { label: "#", value: "node_id" },
    { label: "Nodo", value: "node" },
    { label: "Mac Ethernet", value: "macEthernet" },
    { label: "Mac W1", value: "macW1" },
    { label: "Mac W2", value: "macW2" },
    { label: "IMEI", value: "IMEI" },
    { label: "Serial", value: "serial" },
    { label: "Model", value: "model" },
    { label: "Comentarios", value: "comments" },
    { label: "Creación", value: "creationDate" },
    { label: "Acciones", value: "actions" },
  ];
  const pageTitle = "Listado de nodos";
  const gap = 4;
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: ["100%", null, null, "50%"],
    mb: gap,
    pr: gap,
  };
  return (
    <Route layout="admin">
      <Section sx={{ mx: "auto" }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: ["column", "row"],
            justifyContent: "space-between",
            mb: 4,
          }}
        >
          <Heading {...theme.variant.heading}>{pageTitle}</Heading>
          <Button
            onClick={() => {
              navigate("/nodes/add-node");
            }}
          >
            Añadir nuevo nodo
          </Button>
        </Box>
        <Box>
          <FinalForm
            onSubmit={async () => {}}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: ["column", null, "row"],
                    mb: 8,
                  }}
                >
                  <Box
                    as="input"
                    id="search"
                    name="search"
                    onChange={(e) => {
                      setSearchValue({ ...searchValue, name: e.target.value });
                      sampleToggle();
                    }}
                    placeholder="Search"
                    sx={{
                      ...theme.variant.input.primary,
                      // boxShadow: error ? '0 0 0 2px rgba(255, 0, 0, .25)' : null,
                      mb: 4,
                      mr: 4,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                    type="text"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as="input"
                    id="macEth"
                    name="macEth"
                    onChange={(e) => {
                      setSearchValue({
                        ...searchValue,
                        mac_eth: e.target.value,
                      });
                      sampleToggle();
                    }}
                    placeholder="Mac Ethernet"
                    sx={{
                      ...theme.variant.input.primary,
                      // boxShadow: error ? '0 0 0 2px rgba(255, 0, 0, .25)' : null,
                      mb: 4,
                      mr: 4,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                    type="text"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as="input"
                    id="imei"
                    name="imei"
                    onChange={(e) => {
                      setSearchValue({ ...searchValue, imei: e.target.value });
                      sampleToggle();
                    }}
                    placeholder="IMEI"
                    sx={{
                      ...theme.variant.input.primary,
                      // boxShadow: error ? '0 0 0 2px rgba(255, 0, 0, .25)' : null,
                      mb: 4,
                      mr: 4,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                    type="text"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as="input"
                    id="serial"
                    name="serial"
                    onChange={(e) => {
                      setSearchValue({
                        ...searchValue,
                        serial: e.target.value,
                      });
                      sampleToggle();
                    }}
                    placeholder="Serial"
                    sx={{
                      ...theme.variant.input.primary,
                      // boxShadow: error ? '0 0 0 2px rgba(255, 0, 0, .25)' : null,
                      mb: 4,
                      mr: 4,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                    type="text"
                    wrapperSx={colStyled}
                  />
                </Box>
              </form>
            )}
          />
        </Box>
        <Box>
          {loading && <Spinner />}
          {!loading && (
            <>
              <Table
                header={tableHeader}
                renderEmpty="Sin datos"
                rows={get(nodeData, "data", []).map((node) => [
                  get(node, "node_id", "-"),
                  get(node, "name", "-"),
                  get(node, "mac_eth", "-"),
                  get(node, "mac_w1", "-"),
                  get(node, "mac_w2", "-"),
                  get(node, "imei", "-"),
                  get(node, "serial", "-"),
                  get(node, "model", "-"),
                  get(node, "comments", "-"),
                  get(node, "purchased")
                    ? moment(get(node, "purchased")).format("LLLL")
                    : "-",
                  <Box
                    as="button"
                    color="mediumGreen"
                    onClick={() => {
                      setNode(get(node, "node_id"));
                      deleteToggle();
                    }}
                  >
                    <Icon svg="remove" sx={{ width: "18px" }} />
                  </Box>,
                ])}
                tableSx={{
                  borderCollapse: "collapse",
                  overflow: "visible",
                }}
              />
              {nodeData.total > 0 && (
                <DashboardPaginator
                  buttonSx={{
                    color: "primary",
                  }}
                  enableJumpToPage
                  filters={filters}
                  pageCriteria={pageCriteria}
                  pageTotal={get(nodeData, "data", []).length}
                  total={get(nodeData, "total")}
                  totalJumpToPages={totalJumpToPages}
                />
              )}
            </>
          )}
        </Box>
        <Modal
          bodySx={{ pb: 0 }}
          footer={
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Button
                onClick={async () => {
                  const data = await post("/del-node", { node_id: node });
                  toastMessage({
                    message:
                      get(data, "success") === STATUS.INACTIVE
                        ? get(data, "msm", "Internal server Error")
                        : "Node Deleted Successfully!",
                    toast,
                    type:
                      get(data, "success") === STATUS.INACTIVE
                        ? "error"
                        : "success",
                  });
                  deleteToggle();
                  sampleToggle();
                }}
                sx={{ mr: 3 }}
              >
                Yes
              </Button>{" "}
              <Button
                onClick={() => {
                  deleteToggle();
                }}
                sx={{ ml: 3 }}
              >
                Cancel
              </Button>
            </Box>
          }
          onClose={deleteToggle}
          open={deleteModal}
        >
          <Box
            sx={{
              color: "mediumGreen",
              display: "flex",
              justifyContent: "center",
              my: 5,
            }}
          >
            Are you sure to delete this node?
          </Box>
        </Modal>
      </Section>
    </Route>
  );
};
export default NodeListingPage;
