import React from "react";
import { Router } from "@reach/router";

import NodeListingPage from "../components/PageComponents/NodeListingPage";
import NodeAddPage from "../components/PageComponents/NodeAddPage";

const nodes = (props) => (
  <Router>
    <NodeListingPage {...props} path="/nodes" />
    <NodeAddPage {...props} path="/nodes/add-node" />
  </Router>
);

export default nodes;
